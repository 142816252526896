<template>
	<div :class="themeClass" class="max-width1100 margin-t-20 page-box">
		<div class="common-functions-box flex-colum border-E4E4E4 padding-lr-15 padding-t-15 bg-fff">
			<div class="flex-row-end-between common-functions-title-box">
				<div class="font-size15 font-color-1e1e1e font-weight700">常用功能</div>
				<div class="edit-button background-color-theme flex-row-center-center border-theme pointer" @click="editFunction">{{ showFunction ? '完成' : '编辑' }}</div>
			</div>
			<div class="margin-t-15 flex-row-wrap">
				<div class="domain-box flex-column-center-center margin-r-12 position-relative margin-b-15" v-for="(item,index) of empOftenApplication"
				 :key="'selectData'+index">
					<!-- <svg class="icon" aria-hidden="true" style="width: 20px;height: 20px;">
						<use :xlink:href="'#'+item.fIconClass"></use>
					</svg> -->
					<div style="width: 23px;height: 23px;">
						<img :src="item.fPath" alt="" style="width: 100%;height: 100%;" />
					</div>
					<div class="margin-t-10 font-size13 font-color-333">{{item.fIconClassName}}</div>
					<i @click="deleteFun(item, index)" v-if="item.fUserID != -1 && showFunction" class="el-icon-error font-size17 font-color-FF0000 position-absolute icon-error"></i>
				</div>
			</div>
		</div>
		<div class="margin-t-10 padding-lr-20 padding-t-13 bg-F8F9FE">
			<div class="flex-row-end-between all-functions-title-box">
				<div class="font-size15 font-color-1e1e1e font-weight700">所有功能</div>
				<div class="search-box">
					<el-input class="" placeholder="" v-model="searchText">
						<i slot="suffix" class="el-input__icon el-icon-search pointer" @click="getApplicationByUserAndSys"></i>
					</el-input>
				</div>
			</div>
			<div class="margin-t-10 flex-row-between-between-wrap padding-b-10">
				<div v-for="(item,index) of allData" :key="'allData'+index" class="function-list flex-row-space-between-center bg-fff padding-lr-15">
					<div class="flex-row-align-center">
						<!-- <i class="iconfont font-size14" :class="item.fIconClass"></i> -->
						<!-- <svg-icon icon-class="icon-quanbudingdan" style="width: 15px;height: 15px;" /> -->
						<!-- <svg class="icon" aria-hidden="true" style="width: 20px;height: 20px;">
							<use :xlink:href="'#'+item.fIconClass"></use>
						</svg> -->
						<div style="width: 23px;height: 23px;">
							<img :src="item.fPath" alt="" style="width: 100%;height: 100%;" />
						</div>
						<div class="font-color-333 font-size14 margin-l-10">{{item.fIconClassName}}</div>
					</div>
					<div @click="confirm(index)" v-if="!item.checked && showFunction" class="state-box font-size14 flex-row-center-center font-color-999">添加</div>
					<!-- <div @click="cancel(index)" v-if="item.checked && showFunction" class="state-box font-size14 flex-row-center-center color-theme">取消</div> -->
				</div>
			</div>
		</div>
		<div class="padding-lr-15 flex-colum">
			<div class="flex-row-center-center margin-t-20 margin-b-30">
				<pagination @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" :currentPage="currentPage"
				 :pagesize="pagesize" :total="total">
				</pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	import pagination from '@/components/pagination/pagination.vue'
	export default {
		components: {
			pagination
		},
		data() {
			return {
				searchText: "", //搜索框输入内容
				showFunction: false, //编辑控制
				fixedData: [],
				allData: [],
				total: 0, //总条数
				pagesize: 20, //每页显示条目个数
				currentPage: 1, //当前页
				empOftenApplication: []
			}
		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		mounted() {
			this.getEmpOftenApplication();
		},
		methods: {
			editFunction() {
				if (!this.showFunction) {
					this.showFunction = true
				} else {
					let data = [];
					this.empOftenApplication.forEach((item, index) => {
						if (item.fUserID != -1) {
							data.push(item);
						}
					})
					this.ApiRequestPostNOMess("api/mall/sys/emp-often-application/create", data).then(res => {
						this.showFunction = false
						this.getEmpOftenApplication();
					}).catch(err => {

					})
				}
			},
			// 查询当前用户登录后的常用操作权限
			getEmpOftenApplication() {
				this.ApiRequestPostNOMess("api/mall/sys/emp-often-application/get-list", {}).then(res => {
					res.obj.items.splice(res.obj.items.length - 1, 1);
					this.empOftenApplication = res.obj.items;
					this.getApplicationByUserAndSys();
				}).catch(err => {

				})
			},
			// 查询下方用户的所拥有的操作
			getApplicationByUserAndSys() {
				this.ApiRequestPostNOMess('api/mall/sys/application-theme-detail/get-currentTime-application-icon', {
					maxResultCount: this.pagesize,
					skipCount: (this.currentPage - 1) * this.pagesize,
					filter: this.searchText,
					sorting: ""
				}).then(res => {
					res.obj.items.forEach(item => {
						item.checked = false;
						this.empOftenApplication.forEach((item1, index) => {
							if (item.fApplicationID == item1.fApplicationID) {
								item.checked = true;
								console.log('item', item);
							}
						});
					})
					this.total = res.obj.totalCount;
					this.allData = res.obj.items;
				}).catch(err => {

				})
			},
			deleteFun(item, index) {
				this.empOftenApplication.splice(index, 1);
				let index_2 = this.allData.indexOf(this.allData.find(
					function(element) {
						return element.fApplicationID === item.fApplicationID;
					}))
				this.allData[index_2].checked = false
			},
			cancel(index) {
				this.allData[index].checked = false;
				this.empOftenApplication.splice(
					this.empOftenApplication.indexOf(this.empOftenApplication.find(
						function(element) {
							return element.fApplicationID === this.allData[index].fApplicationID;
						})),
					1);
			},
			confirm(index) {
				let findData = this.empOftenApplication.find(findItem => findItem.fApplicationID == this.allData[index].fApplicationID)
				if (findData) {
					this.$message('不能重复添加功能')
					return;
				}
				this.allData[index].checked = true;
				this.empOftenApplication.push(this.allData[index])
			},




			//编辑功能
			// editFunction() {
			// 	this.selectFunction = !this.selectFunction;
			// },
			//pageSize（每页个数） 改变时会触发
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
			},
			//currentPage（当前页数） 改变时会触发
			handleCurrentChange(val) {
				this.currentPage = val;
				console.log(`当前页: ${val}`);
				this.getApplicationByUserAndSys();
			}
		}
	}
</script>

<style lang="scss" scoped>
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
			color: themed('text_color_white');
		}
	}

	.border-theme {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.border-bottom-theme {
		@include themify($themes) {
			border-bottom: 1px solid themed('themes_color');
		}
	}

	.page-box {
		width: 1110px;
	}

	.common-functions-box {
		height: auto;
	}

	.common-functions-title-box {
		height: 26px;
	}

	.edit-button {
		width: 54px;
		height: 24px;
		border-radius: 2px;
	}

	.domain-box {
		width: 78px;
		height: 73px;
		background-color: #f5f8fe;
	}

	.icon-error {
		top: 0;
		right: 0;
	}

	.all-functions-title-box {
		height: 32px;
	}

	.search-box {
		width: 255px;
	}

	.function-list {
		width: 490px;
		height: 50px;
		margin-top: 1px;
	}

	.state-box {
		width: 55px;
		height: 21px;
	}

	.state-box:hover {
		cursor: pointer;
	}

	/deep/.el-input__inner {
		height: 34px !important;
		line-height: 34px !important;
	}

	/deep/.el-input__icon {
		line-height: 34px !important;
	}
</style>
